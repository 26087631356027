// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("channels")

import * as ActiveStorage from '@rails/activestorage';
import "@enginuitygroup/library/dist/library";
import PhotoPreviewer from '../app/PhotoPreviewer';
import ClipboardButton from '../app/ClipboardButton';
import SignUpFormController from '../app/controllers/SignUpForm';
import ProductCreationInterstitialController from '../app/controllers/ProductCreationInterstitial';
import EditProfileController from '../app/controllers/EditProfile';
import ExpandingNavController from '../app/controllers/ExpandingNav';
import ProductActions from '../app/ProductActions';

const controllers = {
  SignUpFormController,
  ProductCreationInterstitialController,
  EditProfileController,
  ExpandingNavController
};

ActiveStorage.start();
// PhotoPreviewer.start();
ClipboardButton.start();
ProductActions.start();

import "../app/index.scss";

import $ from 'jquery';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/popover';
import "bootstrap/js/dist/tooltip";

import 'croppie/croppie.css';

window.$ = $;
window.jQuery = $;

import "../components/save-print";

class HQProgressBar {
  constructor({ element, speed, itemCount}) {
    this.element     = element
    this.speed       = speed;
    this.itemCount   = itemCount;
    this.progressBar = element.querySelector('.progress-bar');
    this.tickerLis   = element.querySelectorAll('.progress-content li');

    this.timer = setTimeout(this.tick.bind(this, 0), this.speed);
  }

  tick(tickNumber) {
    if (tickNumber < this.itemCount) {
      this.setTicker(tickNumber);
      this.setProgress(tickNumber);
      this.timer = setTimeout(this.tick.bind(this, tickNumber + 1), this.speed);
    }
  }

  setTicker(tickNumber) {
    this.tickerLis.forEach((li, index) => {
      (index == tickNumber) ? li.classList.add("active") : li.classList.remove("active");
    });
  }

  setProgress(tickNumber) {
    const pct = (tickNumber / this.itemCount) * 100;
    this.progressBar.style.width = `${pct}%`;
  }
}

class App {
  onPageLoad() {
    document.querySelectorAll('[data-controller]').forEach(el => {
      const controllerName = el.getAttribute('data-controller');
      controllers[controllerName] && new controllers[controllerName](el);
    });

    $('[data-toggle="tooltip"]').tooltip();
    this.createProgressBars();

  }

  createProgressBars() {
    document.querySelectorAll('.hq-progress-bar').forEach(progressBar => {
      const speed     = parseInt(progressBar.dataset.speed || 1000);
      const itemCount = progressBar.querySelectorAll('.progress-content li').length;

      const bar = new HQProgressBar({
        element:   progressBar,
        speed:     speed,
        itemCount: itemCount
      });
    });
  }
}

let app = new App();
document.addEventListener('turbolinks:load', app.onPageLoad.bind(app));

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("app/react", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
